import "the-new-css-reset/css/reset.css";
import "../styles/app.scss";
// eslint-disable-next-line no-console
console.log("Hello! 😬\nCatch me here: %ccontact[at]realhe.ro", "color: Red");
window.addEventListener("resize", function () {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
});
window.dispatchEvent(new Event("resize"));
// Prevent on load transitions
document.addEventListener("DOMContentLoaded", function () {
    var node = document.querySelector(".preload-transitions");
    node === null || node === void 0 ? void 0 : node.classList.remove("preload-transitions");
});
// Mail protect
var emails = document.getElementsByClassName("email");
Object.entries(emails).forEach(function (_a) {
    var _b;
    var elm = _a[1];
    var parentNode = elm.parentNode;
    if (parentNode) {
        var email = ((_b = parentNode.dataset) === null || _b === void 0 ? void 0 : _b.email);
        if (email) {
            // eslint-disable-next-line no-param-reassign
            elm.textContent = window.atob(email);
        }
        else {
            // eslint-disable-next-line no-param-reassign
            elm.textContent = window.atob("Mail me");
        }
    }
});
// Mailto
var mailMeHandler = function (e) {
    var target = e.target;
    if (target.matches("[data-action=mail-me]")) {
        e.preventDefault();
        var email = target.dataset.email;
        if (typeof email === "string") {
            var decodedEmail = window.atob(email);
            window.location.href = "mailto:" + decodedEmail;
        }
        else {
            throw new Error("No email address provided");
        }
    }
};
document.addEventListener("click", mailMeHandler);
document.addEventListener("touchend", mailMeHandler);
